import { shortenAddress } from "../utils/utils";
import { mintAmount, zeroAddress, contractABI, contractAddress} from "../utils/constants";
import { ethers, BigNumber } from "ethers";
import { useState, useEffect } from "react";
import {toHex} from 'to-hex';
import { Loader } from "./index";
import "../App.css";

const { ethereum } = window;

// Polygon details
  const setChainID = '0x89'; // '137'
  const setChainName ='Polygon Mainnet';
  const setRpcURLs = [process.env.REACT_APP_POLYGON_URL, 'https://polygon-rpc.com'];
  const setBlockExplorerUrls = ['https://polygonscan.com'];
  const network = 'matic';
  const apiKey = process.env.REACT_APP_POLYGON_API_KEY;
  const privateKey = process.env.REACT_APP_POLYGON_PRIVATE_KEY

// Mumbai details
  // const setChainID = '0x13881'; // '80001'
  // const setChainName = 'Matic Mumbai Testnet';
  // const setRpcURLs = [process.env.REACT_APP_MUMBAI_URL, 'https://rpc-mumbai.maticvigil.com/'];
  // const setBlockExplorerUrls = ['https://mumbai.polygonscan.com']; 
  // const network = 'maticmum';
  // const apiKey = process.env.REACT_APP_MUMBAI_API_KEY;
  // const privateKey = process.env.REACT_APP_MUMBAI_PRIVATE_KEY;

export default function Minter () {
  const [supply, setSupply] = useState([]);
  const [account, setAccount] = useState("");
  const [minted, setMinted] = useState(false);
  const [hash, setHash] = useState(null);
  const [token, setToken] = useState("No NFT");
  const [isLoading, setIsLoading] = useState(false);
  // const [transactions, setTransactions] = useState([]);
  // getTransactions();
  // `https://mumbai.polygonscan.com/tx/${hash}`
  
  const connectWallet = async () => {
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    setAccount(accounts[0]);

    const currentChain = ethereum.chainId;
      if(currentChain !== setChainName){

        switchPolygon();

      }
  }

  // const reloadFn = () => {
    // window.location.reload(true);
  // };

  const createContract = async () => {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      
      return contract;
  }

  const getSupply = async () => {
    if (ethereum) {
        const contractInstance = await createContract();

        try {
        const ftSupply = await contractInstance.CURRENT_FT_ID();
        const nftSupply = await contractInstance.CURRENT_NFT_ID();
        setSupply([ftSupply.toString() - 1, nftSupply.toString() - 1]);
        setToken(nftSupply.toString());

        console.log("nftSupply: " + supply[1]);
        console.log("token: " + token);

        } catch (error) {
            console.log(error);
        }
      };
    }

  //const sendTransaction = async () => {          
  //  if (ethereum) {
  //    const contractInstance = await createContract();
  //    try {
//
  //        // 1. Signer Contract instance
  //        const transactionHash = await contractInstance.mint(mintAmount);
//
  //        setIsLoading(true);
  //        await transactionHash.wait();
  //        setIsLoading(false);
//
  //        getSupply();
  //        setHash(transactionHash.hash);
//
  //        console.log(transactionHash);
//
  //        //contractInfo();
//
  //      } catch (error) {
  //      console.log(error);
//
  //      throw new Error("No ethereum object");
  //    }
  // };
  //}

  const freeTransaction = async () => {

    if (ethereum) {

      console.log("Metamask Detected")

      try {

        const provider = new ethers.providers.AlchemyProvider(network, apiKey);

        const signer = new ethers.Wallet(privateKey, provider);

        const freeContract = new ethers.Contract(contractAddress, contractABI, signer);
        
        const connectedWallet = freeContract.connect(signer);

        const gasPrice = signer.getGasPrice();
        const tx = await connectedWallet.mint(account, {gasLimit: 1000000, gasPrice: gasPrice});

        setIsLoading(true);
        await tx.wait();
        // console.log(tx.hash);
        setIsLoading(false);

        // const tokenIDs = await connectedWallet.walletOfOwner(account.toString());
        // await tx.wait();

        // const newSupply = Number(supply) + 1;
        // console.log(newSupply);
        // setSupply(newSupply.toString());
        // console.log(newSupply.toString());


        setHash(tx.hash);

        // setToken(tokenIDs.slice(-1).toString());

        // setMinted(true);

        // console.log({supply});

        console.log(tx.hash);

      } catch (error) {
             console.log(error);
      }
    }
  }

  const switchPolygon = async () => {

    if (ethereum) {

      console.log("Metamask Detected")

    try {
      //try switch to desired chain
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: setChainID }],
      });
    } catch (error) {
        // if error 4902 returned we need to add the chain to user's wallet
      if (error.code === 4902 || error.code === -32603) {
        try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            { 
              chainId: setChainID,
              chainName: setChainName,
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC', // 2-6 characters long
                decimals: 18,
              },
              rpcUrls: setRpcURLs,
              blockExplorerUrls: setBlockExplorerUrls
          },
        ],
      });
      
    } catch (error) {
      console.log(error);
    }
  }
    }
  } else {
    console.log("Metamask not detected")
  }
}
  
  // const addToken = async () => {
    // 
    // if (ethereum) {
// 
      // console.log("Metamask Detected")
// 
      // await ethereum.request({
        // method: 'wallet_watchAsset',
        // params: {
          // type: 'ERC20',
          // options: {
            // address: contractAddress,
            // symbol: 'ECK',
            // decimals: 18,
          // },
        // },
      // })
      // .then((success) => {
        // if (success) {
          // console.log('Token successfully added to wallet!');
        // } else {
          // throw new Error('Something went wrong.');
        // }
      // })
      // .catch(console.error);
      // }  
    // };


  // const getTransactions = async () => {
    // 
    // const provider = new ethers.providers.Web3Provider(ethereum);
    // const contractInstance = await createContract();
    // const block = await provider.getBlockNumber();
    // console.log(`Current Block: ${block}`)
    // 
    // const transferEvents = await contractInstance.queryFilter('Transfer', block-5, block);
    // const last9events = transferEvents
          // .filter(event => event.args[0] !== zeroAddress)
          // .slice(-9)
          // .map(x => [x.args.to, x.transactionHash, BigNumber.from(x.args.tokenId._hex).toString()]);
    // console.log("Last 9 Events");
    // console.log(last9events);
  // }

  // Check it out on <a href={`https://testnets.opensea.io/assets/mumbai/0xC8CF8e24682053769949399f20a4CCC7eB75662E/${token}`} target="_self"
    
  useEffect(() => {
    getSupply();
  }, []);

  // useEffect(() => {
    // getSupply();
  // }, [supply]);


  return (
    <div>
      <div className="banner">
          {account == ""
            ?
            <div>
              <h2>Connect your Metamask wallet</h2>
              <p className="paragraph">Please connect your<br />Metamask wallet to mint an NFT</p>
              <p className="paragraph">Artworks Minted<br />{supply[1]}/250</p>
              <p className="paragraph">Membership Cards Minted<br />{supply[0]}/500</p>
              <button onClick={connectWallet} className="noButton" role="button">Connect Wallet</button>
            </div>
            :
            <div>
              <h2>Metamask Connected</h2>
              <p className="paragraph">Wallet:<br />{shortenAddress(account)}</p>
              <p className="paragraph">Artworks Minted<br />{supply[1]}/250</p>
              <p className="paragraph">Membership Cards Minted<br />{supply[0]}/500</p>
            </div>
            }
      </div>
      <div className="lowerbanner">
            {isLoading
              ? <Loader />
              : <button onClick={freeTransaction} className="noButton" role="button">Mint NFT</button>
            }
      </div>
      <div className="confirmation">
            {hash
              ?
              <div>
                <p>✅ Transaction success!</p>
                <p>Please copy the token address: {contractAddress}</p>
                <p>and remember your token IDs! 0 & {token}</p>
              </div>
              :
              <div>
                <p></p>
              </div>
            }
      </div>
    </div>
    );
};