import { NoMetamask, Minter } from "./components/index";
import './App.css';

const { ethereum } = window;

function App() {

  return (
    <div className="welcome" > 
      <div className= "nav">
          <img src ={require(`./assets/images/MARCOPOLO_logo.png`)} className= "mpIcon"/>
      </div>
      {!ethereum ? <NoMetamask /> : < Minter />}
  </div>
  );
};

export default App;
