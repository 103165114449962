import '../App.css';

export default function NoMetamask() {
    return (
    <div className= "banner">
          <h2>Welcome to Marcopolo NFT</h2>
          <div className="paragraph">
              <p>Metamask not detected. You need to access this page<br/>through the Metamask app to mint your NFT.</p>
          </div>
          <div className="paragraph">
              <p>Press "Download Metamask" to download the app or,<br/>if you already have it installed on your phone,<br/>press "Open Metamask".</p>
          </div>
          <div className="container">
              <a href="https://metamask.io/download/" target="_blank"><button className= "noButton" role="button">Download Metamask</button></a>
              <a href="dapp://nft.marcopoloauctions.com" ><button className= "noButton" role="button">Open Metamask</button></a>
          </div>
    </div>
    )}