// Biconomy working contract and abi on Mumbai
// import abi from "../abi/GIVEAWAY_V7_Biconomy.json";
// export const contractAddress = "0x25f3f86462581591703490673B7EB9F714978F67";

// Deployed promotion site contract and abi on Polygon
import abi from "../abi/marcopolo_promotion.json";
export const contractAddress = "0x43E05E50aaF842F624341E13657BAde5E6beaA00";

export const contractABI = abi;
export const zeroAddress = "0x0000000000000000000000000000000000000000";
export const mintAmount = 1;
